import React from 'react';

import './styles.css';


const Dashboard = (props) => {
  return (
    <React.Fragment>
        
        <a href="/admin">Admin Dashboard</a>

    </React.Fragment>
  );
};

export default Dashboard;
